import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private url: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getNotification(id: number): Observable<any> {

    return this.http.get<any>(this.url + '/api/notificacions/' + id);
  }

  getNotifications(page: number, filter: string, communityId: number): Observable<any> {

    if (communityId) {

      return this.http.get<any>(this.url + '/api/comunidads/' + communityId + '/notificaciones' +
                                '?page=' + page +
                                '&order[id]=desc' +
                                (filter === '' ? '' : '&titulo=' + filter) +
                                (filter === '' ? '' : '&mensaje=' + filter) +
                                (filter === '' ? '' : '&comunidades.nombre=' + filter));

    } else {

      return this.http.get<any>(this.url + '/api/notificacions' +
                                '?page=' + page +
                                '&order[id]=desc' +
                                (filter === '' ? '' : '&titulo=' + filter) +
                                (filter === '' ? '' : '&mensaje=' + filter) +
                                (filter === '' ? '' : '&comunidades.nombre=' + filter));

    }

  }

  newNotification(titulo: string, mensaje: string, ids: number[]) {

    const comunidades: string [] = [];
    ids.forEach(id => {
      if (id !== 0) {
        comunidades.push('/api/comunidads/' + id);
      }
    });

    const body = JSON.stringify({
      titulo,
      mensaje,
      comunidades,
      enviado: false
    });

    return this.http.post<any>(this.url + '/api/notificacions', body);

  }

  editNotification(notificationId: number, titulo: string, mensaje: string, ids: number[]) {

    const comunidades: string [] = [];
    ids.forEach(id => {
      if (id !== 0) {
        comunidades.push('/api/comunidads/' + id);
      }
    });

    const body = JSON.stringify({
      titulo,
      mensaje,
      comunidades,
      enviado: false
    });

    return this.http.put<any>(this.url + '/api/notificacions/' + notificationId, body);

  }

  deleteNotification(id: number) {

    return this.http.delete<any>(this.url + '/api/notificacions/' + id);

  }

}
