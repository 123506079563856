import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatSelect } from '@angular/material';
import { NotificationService } from 'src/app/_services/notification.service';
import { CommunityService } from 'src/app/_services/community.service';

@Component({
  selector: 'app-new-notification',
  templateUrl: './new-notification.component.html',
  styleUrls: ['./new-notification.component.scss']
})
export class NewNotificationComponent implements OnInit {

  created = false;
  newNotification: DataTableNotifications;

  options: FormGroup;
  titulo = new FormControl('', [Validators.required]);
  mensaje = new FormControl('', [Validators.required]);

  defaultCommunityId: number;

  communities: {id: number, nombre: string}[] = [];
  filteredCommunities: {id: number, nombre: string}[] = [];
  selectedCommunities: number[] = [];
  allSelected = false;

  @ViewChild(MatSelect) community: MatSelect;

  constructor(
    private matDialogRef: MatDialogRef<NewNotificationComponent>,
    private notificationService: NotificationService,
    private communityService: CommunityService,
    public fb: FormBuilder
  ) {
    this.options = this.fb.group({
      color: 'primary'
    });
  }

  ngOnInit() {
    this.getCommunities();
  }

  submit(valid: boolean) {
    if (valid && this.selectedCommunities.length > 0 && this.selectedCommunities !== [0]) {
      this.notificationService.newNotification(this.titulo.value, this.mensaje.value, this.selectedCommunities).subscribe(
        data => {
          this.created = true;
          this.newNotification = {
            id: data.id,
            titulo: data.titulo,
            mensaje: data.mensaje,
            comunidades: data.comunidades
          };
          this.closeDialog();
        }
      );
    }
  }

  getCommunities() {
    this.communityService.getCommunitiesList()
    .subscribe(data => {
      const communities: any[] = data['hydra:member'];
      communities.forEach(item => {
        this.communities.push({id: item.id, nombre: item.nombre});
        this.filteredCommunities.push({id: item.id, nombre: item.nombre});
      });
      if (this.defaultCommunityId) {
        this.selectedCommunities.push(this.defaultCommunityId);
        console.log(this.defaultCommunityId);
        console.log(this.selectedCommunities);
        this.community.disabled = true;
      }
    });
  }

  filterCommunities(filter: string) {
    if (this.communities) {
      const filterValue = filter.toLowerCase();
      this.filteredCommunities = this.communities.filter(option => option.nombre.toLowerCase().includes(filterValue));
    }
    this.manageAllSelected();
  }

  manageAllSelected() {
    // console.log('-------------------------------');
    // console.log('| Variable: ' + this.allSelected);
    // console.log('| Selected Communities: ' + this.selectedCommunities.length);
    // console.log('| Filtered Communities: ' + this.filteredCommunities.length);
    // console.log('-------------------------------');
    if (!this.allSelected) {
      if (this.allFilteredSelected() && !(this.selectedCommunities.indexOf(0) > -1)) {
        this.selectedCommunities = [0].concat(this.selectedCommunities);
        this.allSelected = true;
      } else if (this.selectedCommunities.indexOf(0) > -1 && !this.allFilteredSelected()) {
        let selected: number[] = [];
        this.filteredCommunities.forEach(item => {
          selected.push(item.id);
        });
        selected = selected.concat(this.selectedCommunities);
        this.selectedCommunities = selected.filter((item, index) => selected.indexOf(item) === index);
        this.allSelected = true;
      }
    } else {
      if (this.allFilteredSelected() && !(this.selectedCommunities.indexOf(0) > -1)) {
        const remove: number[] = [];
        this.filteredCommunities.forEach(item => {
          remove.push(item.id);
        });
        this.selectedCommunities = this.selectedCommunities.filter(x => !remove.includes(x));
        this.allSelected = false;
      } else if (this.selectedCommunities.indexOf(0) > -1 && !this.allFilteredSelected()) {
        const selected: number[] = [];
        this.selectedCommunities.forEach(item => {
          if (item !== 0) {
            selected.push(item);
          }
        });
        this.selectedCommunities = selected;
        this.allSelected = false;
      }
    }
  }

  allFilteredSelected(): boolean {
    let response = this.filteredCommunities.length > 0;
    this.filteredCommunities.forEach(item => {
      if (this.selectedCommunities.indexOf(item.id) < 0) {
        response = false;
      }
    });
    return response;
  }

  closeDialog() {
    this.matDialogRef.close();
  }

}

export interface DataTableNotifications {
  id: number;
  titulo: string;
  mensaje: string;
  comunidades: any[];
}
