import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { DocumentService } from 'src/app/_services/document.service';

@Component({
  selector: 'app-edit-folder',
  templateUrl: './edit-folder.component.html',
  styleUrls: ['./edit-folder.component.scss']
})
export class EditFolderComponent implements OnInit {

  options: FormGroup;

  name = new FormControl('', [Validators.required]);

  edited = false;
  folderId: number;

  constructor(
    private documentService: DocumentService,
    private matDialogRef: MatDialogRef<EditFolderComponent>,
    fb: FormBuilder,
  ) {
    this.options = fb.group({
      color: 'primary'
    });
  }

  ngOnInit() {
  }

  submit(valid: boolean) {
    if (valid) {
      this.documentService.editFolderName(
        this.folderId, this.name.value
      ).subscribe(
        data => {
          this.edited = true;
          this.closeDialog();
        }
      );
    }
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  getNameErrorMessage() {
    return 'Nombre obligatorio';
  }

}
