/**
 * Created by andermanci on 14/3/18.
 */
import { Injectable, Injector } from '@angular/core';
import {
    HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { flatMap, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    helper = new JwtHelperService();
    private url: string = environment.apiUrl;

    constructor(
        private router: Router,
        private auth: AuthenticationService,
        private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.auth.isLoggedIn) {
            const headers = {};
            let newRequest: HttpRequest<any>;
            if (this.helper.isTokenExpired(this.auth.getToken()) && !request.url.endsWith('/api/token/refresh')) {
                console.log('EXPIRED TOKEN');
                return this.refreshToken(request, next);
            } else {
                if (!this.isUrlWithoutToken(request.url)) {
                    // tslint:disable-next-line:no-string-literal
                    headers['Authorization'] = 'Bearer ' + this.auth.getToken();
                }
                if (!this.isUrlWithoutJson(request.url)) {
                    headers['Content-Type'] = 'application/json';
                }
                newRequest = request.clone({
                    setHeaders: headers
                });
                return next.handle(newRequest);
            }
        } else {
            const newRequest = request.clone({
                setHeaders: {
                    'Content-Type':  'application/json',
                }
            });
            return next.handle(newRequest);
        }
    }

    isUrlWithoutToken(url: string): boolean {
        const excludedUrl = [
            '/login_check',
            '/login_code_check',
            '/token/refresh'
        ];

        let status = false;

        for (const key in excludedUrl) {
            if (url.includes(excludedUrl[key])) {
                status = true;
            }
        }

        return status;
    }

    isUrlWithoutJson(url: string): boolean {
        const excludedUrl = [
            '/comunidad/rootFolders',
            '/documentos/upload_file',
            '/documentos/download'
        ];

        let status = false;

        for (const key in excludedUrl) {
            if (url.includes(excludedUrl[key])) {
                status = true;
            }
        }

        return status;
    }

    refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const auth = this.injector.get(AuthenticationService);
        return auth.refresh().pipe(
            flatMap(
                data => {
                    if (data) {
                        const newToken = data.token;
                        localStorage.setItem('token', newToken);
                        return this.intercept(request, next);
                    }
                }
            )
        );
    }

}
