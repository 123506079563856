import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { DocumentService } from 'src/app/_services/document.service';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})
export class CreateFolderComponent implements OnInit {

  options: FormGroup;

  name = new FormControl('', [Validators.required]);

  created = false;
  parentFolder: number;
  community: number;
  newFolder: {id: number, nombre: string, esCarpeta: boolean, esVisible: boolean};

  constructor(
    private documentService: DocumentService,
    private matDialogRef: MatDialogRef<CreateFolderComponent>,
    fb: FormBuilder,
  ) {
    this.options = fb.group({
      color: 'primary'
    });
  }

  ngOnInit() {
  }

  submit(valid: boolean) {
    if (valid) {
      this.documentService.newFolder(
        this.name.value, this.community, this.parentFolder
      ).subscribe(
        data => {
          this.created = true;
          this.newFolder = {
            id: data.id,
            nombre: this.name.value,
            esCarpeta: true,
            esVisible: true
          };
          this.closeDialog();
        }
      );
    }
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  getNameErrorMessage() {
    return 'Nombre obligatorio';
  }
}
