import { Component, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/_services/document.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-delete-folder',
  templateUrl: './delete-folder.component.html',
  styleUrls: ['./delete-folder.component.scss']
})
export class DeleteFolderComponent implements OnInit {

  id: number;
  deleted: boolean;

  constructor(
    private documentService: DocumentService,
    private matDialogRef: MatDialogRef<DeleteFolderComponent>,
  ) { }

  ngOnInit() {
  }

  deleteFolder() {
    this.documentService.deleteFolder(this.id)
      .subscribe(
        data => {
          this.deleted = true;
          this.matDialogRef.close();
        }
      );
  }

  closeDialog() {
    this.matDialogRef.close();
  }

}
