import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { MatSort, MatPaginator, MatInput, MatDialogRef, MatDialog } from '@angular/material';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { NotificationService } from 'src/app/_services/notification.service';
import { NewNotificationComponent } from 'src/app/dialogs/notification/new-notification/new-notification.component';
import { DeleteNotificationComponent } from 'src/app/dialogs/notification/delete-notification/delete-notification.component';
import { EditNotificationComponent } from 'src/app/dialogs/notification/edit-notification/edit-notification.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunityService } from 'src/app/_services/community.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  filterValue: string;
  displayedColumns: string[] = ['titulo', 'mensaje', 'comunidad', 'actions'];
  resultsLength = 0;
  pageSizeOptions = 10;
  isLoadingResults = true;
  isRateLimitReached = false;

  notificationEmmiter: EventEmitter<any> = new EventEmitter();
  newNotificationDialog: MatDialogRef<NewNotificationComponent>;
  editNotificationDialog: MatDialogRef<EditNotificationComponent>;
  deleteNotificationDialog: MatDialogRef<DeleteNotificationComponent>;

  communitiesList: any[];

  data: DataTableNotifications[] = [];

  defaultCommunityId: number;
  communityName: string;

  isAdmin: boolean;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('filterInput', { read: MatInput }) filterInput: MatInput;

  constructor(
    private notificationService: NotificationService,
    private communityService: CommunityService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('role') === 'ROLE_ADMIN';
    this.filterValue = '';
    this.resultsLength = this.data.length;

    this.route.params.subscribe(routeParams => {
      this.defaultCommunityId = parseInt(routeParams.communityId, 10);
      if (this.defaultCommunityId) {
        this.getCommunity();
      }
    });

    merge(this.notificationEmmiter)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        return this.notificationService.getNotifications(
          this.paginator.pageIndex + 1, this.filterValue, this.defaultCommunityId);
      }),
      map(data => {
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data['hydra:totalItems'];

        return data['hydra:member'];
      }),
      catchError(error => {
        console.log('ERROR', error);
        this.isLoadingResults = false;
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    ).subscribe(data => {
      console.log(data);
      this.data = data;
    });
  }

  newNotification(): void {
    this.newNotificationDialog = this.dialog.open(NewNotificationComponent, {
      autoFocus: false
    });
    if (window.innerWidth < 600) {
      this.newNotificationDialog.updateSize('80vw', '87%');
    } else {
      this.newNotificationDialog.updateSize('869px', '');
    }

    this.newNotificationDialog.componentInstance.defaultCommunityId = this.defaultCommunityId;

    this.newNotificationDialog.afterClosed().subscribe(() => {
      if (this.newNotificationDialog.componentInstance.created) {
        this.data = [this.newNotificationDialog.componentInstance.newNotification].concat(this.data);
      }
      this.newNotificationDialog = null;
    });
  }

  editNotification(id: number): void {
    this.editNotificationDialog = this.dialog.open(EditNotificationComponent, {
      autoFocus: false
    });
    if (window.innerWidth < 600) {
      this.editNotificationDialog.updateSize('80vw', '87%');
    } else {
      this.editNotificationDialog.updateSize('869px', '');
    }
    this.editNotificationDialog.componentInstance.id = id;

    this.editNotificationDialog.afterClosed().subscribe(() => {
      if (this.editNotificationDialog.componentInstance.edited) {
        const notification = this.data.find(x => x.id === id);
        const index = this.data.indexOf(notification);
        const editedCommunity = this.editNotificationDialog.componentInstance.editNotification;
        this.data = this.data.slice(0, index).concat([editedCommunity]).concat(this.data.slice(index + 1));
      }
      this.editNotificationDialog = null;
    });
  }

  deleteNotification(id: number) {
    this.deleteNotificationDialog = this.dialog.open(DeleteNotificationComponent, {
      autoFocus: false,
    });
    this.deleteNotificationDialog.componentInstance.id = id;

    this.deleteNotificationDialog.afterClosed().subscribe(result => {
      if (this.deleteNotificationDialog.componentInstance.deleted) {
        const notification = this.data.find(x => x.id === id);
        const index = this.data.indexOf(notification);
        this.data = this.data.slice(0, index).concat(this.data.slice(index + 1));
      }
    });
  }

  showCommunities(communities: any[]) {
    this.communitiesList = communities;
  }

  communitiesNames(communities: any[]): string {
    let names = '';
    communities.forEach(item => {
      names += item.nombre + '\n';
    });
    return names;
  }

  getCommunity() {
    this.communityService.getCommunity(this.defaultCommunityId).subscribe(
      data => {
        this.communityName = data.nombre;
      }
    );
  }

  paginatorChange() {
    this.notificationEmmiter.emit();
  }

  applyFilter(filterValue: string) {
    this.filterValue = filterValue;
    this.notificationEmmiter.emit();
  }

  clearFilter() {
    this.filterValue = '';
    this.notificationEmmiter.emit();
  }

  viewDocuments() {
    this.router.navigate(['communities/' + this.defaultCommunityId + '/documents']);
  }

  viewUsers() {
    this.router.navigate(['communities/' + this.defaultCommunityId + '/users']);
  }

}

export interface DataTableNotifications {
  id: number;
  titulo: string;
  mensaje: string;
  comunidades: any[];
}
