import { Component, OnInit } from '@angular/core';
import { CommunityService } from 'src/app/_services/community.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-delete-community',
  templateUrl: './delete-community.component.html',
  styleUrls: ['./delete-community.component.scss']
})
export class DeleteCommunityComponent implements OnInit {

  id: number;
  deleted: boolean;

  constructor(
    private communityService: CommunityService,
    private matDialogRef: MatDialogRef<DeleteCommunityComponent>,
  ) { }

  ngOnInit() {
  }

  deleteCommunity() {
    this.communityService.deleteCommunity(this.id)
      .subscribe(
        data => {
          this.deleted = true;
          this.matDialogRef.close();
        }
      );
  }

  closeDialog() {
    this.matDialogRef.close();
  }

}
