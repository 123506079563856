import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CommunityService } from 'src/app/_services/community.service';
import { MatDialogRef } from '@angular/material';
import { ManagerService } from 'src/app/_services/manager.service';

@Component({
  selector: 'app-edit-community',
  templateUrl: './edit-community.component.html',
  styleUrls: ['./edit-community.component.scss']
})
export class EditCommunityComponent implements OnInit {

  options: FormGroup;

  id: number;
  edited: boolean;

  name = new FormControl('', [Validators.required]);
  cif = new FormControl();
  city = new FormControl();
  postalCode = new FormControl();
  accountNumber = new FormControl();
  communityInsurance = new FormControl();
  elevatorOperator = new FormControl();
  elevatorOperatorPhone = new FormControl();
  plumber = new FormControl();
  plumberPhone = new FormControl();
  electricalTechnician = new FormControl();
  electricalTechnicianPhone = new FormControl();
  insuranceContact = new FormControl();
  insuranceContactPhone = new FormControl();
  accessCodeBoolean = new FormControl();
  accessCode = new FormControl('');
  startAccessCode: string;

  managersIds: string[] = [];
  deleteManagers: number[] = [];
  managers: {
    id: number,
    nombre: string,
    telefono: string,
    email: string
  }[] = [];

  editedCommunity: {
    id: number,
    nombre: string,
    municipio: string,
    codigoAcceso: string,
    tieneCodigoAcceso: boolean
  };

  constructor(
    private communityService: CommunityService,
    private managerService: ManagerService,
    private matDialogRef: MatDialogRef<EditCommunityComponent>,
    fb: FormBuilder,
  ) {
    this.options = fb.group({
      color: 'primary'
    });
  }

  ngOnInit() {
    this.getCommunity();
  }

  submit(valid: boolean) {
    const self = this;

    if (!valid) {
      return;
    }

    if (this.managers.filter(x => x.nombre === '' || x.email === '' || x.telefono === '').length > 0) {
      return;
    }

    this.deleteManagers.forEach(manager => {
      self.managerService.delete(manager).subscribe(
        data => {
          const index = self.deleteManagers.indexOf(manager);
          self.deleteManagers.splice(index, 1);
        }
      );
    });

    // tslint:disable-next-line: only-arrow-functions
    this.managers.forEach(function(manager, i) {
      if (manager.id === -1) {
        self.managerService.newManager(manager.nombre, manager.telefono, manager.email).subscribe(
          data => {
            self.managersIds.push('/api/gestors/' + data.id);
            if (i === self.managers.length - 1) {
              self.submitCommunity();
            }
          }
        );
      } else {
        self.managerService.editManager(manager.id, manager.nombre, manager.telefono, manager.email).subscribe(
          data => {
            self.managersIds.push('/api/gestors/' + data.id);
            if (i === self.managers.length - 1) {
              self.submitCommunity();
            }
          }
        );
      }
    });

    if (this.managers.length === 0) {
      this.submitCommunity();
    }
  }

  submitCommunity() {
    let accessCode: string;
    if (!this.accessCodeBoolean.value) {
      accessCode = this.startAccessCode;
    } else {
      accessCode = this.accessCode.value;
    }

    this.communityService.editCommunity(
      this.id, this.name.value, this.cif.value, this.city.value,
      this.postalCode.value, this.accountNumber.value,
      this.communityInsurance.value, this.managersIds,
      this.elevatorOperator.value, this.elevatorOperatorPhone.value,
      this.electricalTechnician.value, this.electricalTechnicianPhone.value,
      this.plumber.value, this.plumberPhone.value, this.insuranceContact.value,
      this.insuranceContactPhone.value, this.accessCodeBoolean.value, accessCode)
    .subscribe(
      data => {
        this.edited = true;
        this.editedCommunity = {
          id: this.id,
          nombre: this.name.value,
          municipio: this.city.value,
          codigoAcceso: this.accessCode.value,
          tieneCodigoAcceso: this.accessCodeBoolean.value
        };
        this.closeDialog();
      }
    );
  }

  getCommunity() {
    this.communityService.getCommunity(this.id).subscribe(
      data => {
        console.log(data);
        this.name.setValue(data.nombre);
        this.cif.setValue(data.cif);
        this.city.setValue(data.municipio);
        this.postalCode.setValue(data.cp);
        this.accountNumber.setValue(data.numeroCuenta);
        this.communityInsurance.setValue(data.seguro);
        this.elevatorOperator.setValue(data.ascensorista);
        this.elevatorOperatorPhone.setValue(data.telefonoAscensorista);
        this.electricalTechnician.setValue(data.electricista);
        this.electricalTechnicianPhone.setValue(data.telefonoElectricista);
        this.plumber.setValue(data.fontanero);
        this.plumberPhone.setValue(data.telefonoFontanero);
        this.insuranceContact.setValue(data.contactoSeguro);
        this.insuranceContactPhone.setValue(data.telefonoContactoSeguro);
        this.accessCodeBoolean.setValue(data.tieneCodigoAcceso);
        this.startAccessCode = data.codigoAcceso;
        if (this.accessCodeBoolean.value) { this.accessCode.setValue(data.codigoAcceso); }
        if (data.gestores) {
          this.getManagers(data.gestores);
        }
      }
    );
  }

  getManagers(gestores: any[]) {
    console.log(gestores);
    const self = this;
    gestores.forEach(gestor => {
      const id = gestor.split('/')[3];
      self.managerService.getById(id).subscribe(
        data => {
          console.log(data);
          delete data['@context'];
          delete data['@id'];
          delete data['@type'];
          delete data['comunidad'];
          this.managers.push(data);
        }
      );
    });
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  accessCodeCheck() {
    if (this.accessCodeBoolean.value) {
      if (this.startAccessCode !== null) {
        this.accessCode.setValue(this.startAccessCode);
      } else {
        this.accessCode.setValue(Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(7, 9));
      }
      this.editAccessCode();
    } else {
      this.accessCode.setValue('');
    }
  }

  editAccessCode() {
    if (this.accessCodeBoolean.value) {
      if (this.accessCode.value.length < 5) {
        this.accessCode.setErrors({minlength: true});
      } else {
        if (this.startAccessCode !== this.accessCode.value) {
          this.communityService.isRepeatedCode(this.accessCode.value).subscribe(
            data => {
              if (data['data']) {
                this.accessCode.setErrors({ repeatedCode: true });
              } else {
                this.startAccessCode = this.accessCode.value;
              }
            }
          );
        }
      }
    } else {
      this.accessCode.setValue('');
    }
  }

  getClientNameErrorMessage() {
    return 'Nombre obligatorio';
  }

  getAccessCodeErrorMessage() {
    let error = '';
    if (this.accessCode.hasError('minlength')) {
      error = 'Código de 5 caracteres';
    } else if (this.accessCode.hasError('repeatedCode')) {
      error = 'Código repetido';
    } else {
      error = 'Código de acceso obligatorio';
    }
    return error;
  }

  addManager() {
    this.managers.push({
      id: -1,
      nombre: '',
      telefono: '',
      email: ''
    });
  }

  removeManager(index: number) {
    this.deleteManagers.push(this.managers[index].id);
    this.managers.splice(index, 1);
  }

}
