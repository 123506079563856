import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NotificationService } from 'src/app/_services/notification.service';
import { CommunityService } from 'src/app/_services/community.service';

@Component({
  selector: 'app-edit-notification',
  templateUrl: './edit-notification.component.html',
  styleUrls: ['./edit-notification.component.scss']
})
export class EditNotificationComponent implements OnInit {

  id: number;
  edited = false;
  editNotification: DataTableNotifications;

  options: FormGroup;
  titulo = new FormControl('', [Validators.required]);
  mensaje = new FormControl('', [Validators.required]);

  defaultCommunityId: number;

  communities: {id: number, nombre: string}[] = [];
  filteredCommunities: {id: number, nombre: string}[] = [];
  selectedCommunities: number[] = [];
  allSelected = false;

  constructor(
    private matDialogRef: MatDialogRef<EditNotificationComponent>,
    private notificationService: NotificationService,
    private communityService: CommunityService,
    public fb: FormBuilder
  ) {
    this.options = this.fb.group({
      color: 'primary'
    });
  }

  ngOnInit() {
    this.getNotification();
  }

  submit(valid: boolean) {
    if (valid && this.selectedCommunities.length > 0 && this.selectedCommunities !== [0]) {
      this.notificationService.editNotification(this.id, this.titulo.value, this.mensaje.value, this.selectedCommunities).subscribe(
        data => {
          this.edited = true;
          this.editNotification = {
            id: data.id,
            titulo: data.titulo,
            mensaje: data.mensaje,
            comunidades: data.comunidades
          };
          this.closeDialog();
        }
      );
    }
  }

  getNotification() {
    this.notificationService.getNotification(this.id).subscribe(
      data => {
        this.titulo.setValue(data.titulo);
        this.mensaje.setValue(data.mensaje);
        data.comunidades.forEach(item => {
          this.selectedCommunities.push(item.id);
        });
        this.getCommunities();
      }
    );
  }

  getCommunities() {
    this.communityService.getCommunitiesList()
    .subscribe(data => {
      const communities: any[] = data['hydra:member'];
      communities.forEach(item => {
        this.communities.push({id: item.id, nombre: item.nombre});
        this.filteredCommunities.push({id: item.id, nombre: item.nombre});
      });
      this.manageAllSelected();
      if (this.defaultCommunityId) {
        this.getCommunity();
      }
    });
  }

  getCommunity() {
    this.communityService.getCommunity(this.defaultCommunityId).subscribe(
      data => {
        // this.community.setValue(data);
        // this.community.disable();
      }
    );
  }

  filterCommunities(filter: string) {
    if (this.communities) {
      const filterValue = filter.toLowerCase();
      this.filteredCommunities = this.communities.filter(option => option.nombre.toLowerCase().includes(filterValue));
    }
    this.manageAllSelected();
  }

  manageAllSelected() {
    // console.log('-------------------------------');
    // console.log('| Variable: ' + this.allSelected);
    // console.log('| Selected Communities: ' + this.selectedCommunities.length);
    // console.log('| Filtered Communities: ' + this.filteredCommunities.length);
    // console.log('-------------------------------');
    if (!this.allSelected) {
      if (this.allFilteredSelected() && !(this.selectedCommunities.indexOf(0) > -1)) {
        this.selectedCommunities = [0].concat(this.selectedCommunities);
        this.allSelected = true;
      } else if (this.selectedCommunities.indexOf(0) > -1 && !this.allFilteredSelected()) {
        let selected: number[] = [];
        this.filteredCommunities.forEach(item => {
          selected.push(item.id);
        });
        selected = selected.concat(this.selectedCommunities);
        this.selectedCommunities = selected.filter((item, index) => selected.indexOf(item) === index);
        this.allSelected = true;
      }
    } else {
      if (this.allFilteredSelected() && !(this.selectedCommunities.indexOf(0) > -1)) {
        const remove: number[] = [];
        this.filteredCommunities.forEach(item => {
          remove.push(item.id);
        });
        this.selectedCommunities = this.selectedCommunities.filter(x => !remove.includes(x));
        this.allSelected = false;
      } else if (this.selectedCommunities.indexOf(0) > -1 && !this.allFilteredSelected()) {
        const selected: number[] = [];
        this.selectedCommunities.forEach(item => {
          if (item !== 0) {
            selected.push(item);
          }
        });
        this.selectedCommunities = selected;
        this.allSelected = false;
      }
    }
  }

  allFilteredSelected(): boolean {
    let response = this.filteredCommunities.length > 0;
    this.filteredCommunities.forEach(item => {
      if (this.selectedCommunities.indexOf(item.id) < 0) {
        response = false;
      }
    });
    return response;
  }

  closeDialog() {
    this.matDialogRef.close();
  }

}

export interface DataTableNotifications {
  id: number;
  titulo: string;
  mensaje: string;
  comunidades: any[];
}
