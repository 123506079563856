import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import {Location} from '@angular/common';


@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(
    public auth: AuthenticationService,
    public router: Router,
    private location: Location
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    const role = localStorage.getItem('role');
    // decode the token to get its payload

    if (role === 'ROLE_ADMIN') {
      return true;
    }

    if (!this.auth.loggedIn() || role !== expectedRole) {
      this.location.back();
      return false;
    }

    return true;

  }
}
