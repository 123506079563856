import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, EmailValidator } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { UserService } from 'src/app/_services/user.service';
import { CommunityService } from 'src/app/_services/community.service';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  options: FormGroup;
  created: boolean;

  name = new FormControl('', [Validators.required]);
  community = new FormControl('', [Validators.required]);
  email = new FormControl('');
  password1 = new FormControl('', [Validators.required]);
  password2 = new FormControl('', [Validators.required]);

  defaultCommunityId: number;

  communities: {id: number, nombre: string}[] = [];
  filteredCommunities: Observable<{id: number, nombre: string}[]>;

  hide = true;
  password1Null: boolean;

  constructor(
    private userService: UserService,
    public fb: FormBuilder,
    private communityService: CommunityService,
    private matDialogRef: MatDialogRef<NewUserComponent>
  ) {
    this.options = this.fb.group({
      color: 'primary'
    });
  }

  ngOnInit() {
    this.getCommunities();
    this.filteredCommunities = this.community.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCommunities(value))
      );
  }

  submit(valid: boolean) {
    if (valid) {
      this.userService.newUser(
        this.name.value, this.email.value, this.password1.value, this.community.value.id)
      .subscribe(
        data => {
          this.created = true;
          this.closeDialog();
        }
      );
    }
  }

  getCommunities() {
    this.communityService.getCommunitiesList()
    .subscribe(data => {
      this.communities = data['hydra:member'];
      if (this.defaultCommunityId) {
        this.getCommunity();
      } else {
        this.community.setValue('');
      }
    });
  }

  getCommunity() {
    this.communityService.getCommunity(this.defaultCommunityId).subscribe(
      data => {
        this.community.setValue(data);
        this.community.disable();
      }
    );
  }

  displayFn(val: any): string {
    if (val !== null) {
      return val.nombre;
    }
  }

  validateEmail() {
    if (this.email.value !== '') {
      const regexp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
      if (regexp.test(this.email.value)) {
        this.userService.validateEmail(this.email.value).subscribe(
          data => {
            if (data['data']) {
              this.email.setErrors({ repeatedEmail: true });
            }
          }
        );
      } else {
        this.email.setErrors({invalid: true});
      }
    }
  }

  passwordMatch() {
    if (this.password1.value !== this.password2.value) {
      this.password2.setErrors({ differentPassword: true });
    }
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  getUserNameErrorMessage() {
    return 'Nombre obligatorio';
  }

  getEmailErrorMessage() {
    const error = this.email.hasError('repeatedEmail') ?
      'Este email ya está en uso' :
      'Email no válido';
    return error;
  }

  getPassword1ErrorMessage() {
    return 'Contraseña obligatoria';
  }

  getPassword2ErrorMessage() {
    const error = this.password2.hasError('differentPassword') ?
      'Las contraseñas no coinciden' :
      'Repetir contraseña';
    return error;
  }

  getUserCommunityErrorMessage() {
    return 'Comunidad obligatoria';
  }

  private _filterCommunities(value: any): any[] {
    if (this.communities) {
      let filterValue;
      if (typeof value === 'string') {
        filterValue = value.toLowerCase();
        return this.communities.filter(option => option.nombre.toLowerCase().includes(filterValue));
      } else {
        return this.communities;
      }
    }
  }
}
