import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  private url: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getById(id: string) {
    return this.http.get<any>(this.url + '/api/gestors/' + id);
  }

  newManager(nombre: string, telefono: string, email: string) {
    const body = JSON.stringify({
      nombre,
      telefono,
      email,
    });
    return this.http.post<any>(this.url + '/api/gestors', body);
  }

  editManager(id: number, nombre: string, telefono: string, email: string) {
    const body = JSON.stringify({
      nombre,
      telefono,
      email,
    });
    return this.http.put<any>(this.url + '/api/gestors/' + id, body);
  }

  delete(id: number) {
    return this.http.delete<any>(this.url + '/api/gestors/' + id);
  }
}
