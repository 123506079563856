import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthenticationService {

  logged: boolean;
  isLoggedIn = false;

  private url: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwt: JwtHelperService
  ) {
    this.isLoggedIn = !this.jwt.isTokenExpired(this.getToken());
  }

  // INSTANCE APP

  login(username: string, password: string) {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { headers };
    const body = JSON.stringify({
      username,
      password
    });

    return this.http.post<any>(this.url + '/api/login_check', body, options);
  }

  loginWithCode(accessCode: string) {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { headers };
    const body = JSON.stringify({
      accessCode
    });

    return this.http.post<any>(this.url + '/api/login_code_check', body, options);
  }

  logout(): void {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('refresh_token');
    this.isLoggedIn = false;
  }

  loggedIn(): boolean {
    return this.isLoggedIn;
  }

  refresh(): Observable<any> {
    const refreshToken = localStorage.getItem('refresh_token');
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { headers };
    const body = {
      refresh_token: refreshToken
    };

    return this.http.post<any>(this.url + '/api/token/refresh', body, options);
  }

  setLoggedIn() {
    this.isLoggedIn = true;
  }

  getToken() {
    return localStorage.getItem('token');
  }

}
