import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CommunityService } from 'src/app/_services/community.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-edit-access-code-dialog',
  templateUrl: './edit-access-code-dialog.component.html',
  styleUrls: ['./edit-access-code-dialog.component.scss']
})
export class EditAccessCodeDialogComponent implements OnInit {

  id: number;
  edited: boolean;

  options: FormGroup;
  accessCode = new FormControl('', [Validators.required, Validators.minLength(5)]);

  constructor(
    private communityService: CommunityService,
    private matDialogRef: MatDialogRef<EditAccessCodeDialogComponent>,
    fb: FormBuilder,
  ) {
    this.options = fb.group({
      color: 'primary'
    });
  }

  ngOnInit() {
  }

  submit(valid: boolean) {
    if (valid) {
      this.communityService.isRepeatedCode(this.accessCode.value).subscribe(
        data => {
          if (data['data']) {
            this.accessCode.setErrors({ repeatedCode: true });
          } else {
            this.editAccessCode();
          }
        }
      );
    }
  }

  editAccessCode() {
    this.communityService.editAccessCode(this.id, this.accessCode.value).subscribe(
      data => {
        this.edited = true;
        this.closeDialog();
      }
    );
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  getAccessCodeErrorMessage() {
    let error = '';
    if (this.accessCode.hasError('minlength')) {
      error = 'Código de 5 caracteres';
    } else if (this.accessCode.hasError('repeatedCode')) {
      error = 'Código repetido';
    } else {
      error = 'Código de acceso obligatorio';
    }
    return error;
  }

}
