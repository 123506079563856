import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatDialogRef, MatDialog, MatInput } from '@angular/material';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { CommunityService } from 'src/app/_services/community.service';
import { NewCommunityComponent } from 'src/app/dialogs/community/new-community/new-community.component';
import { DeleteCommunityComponent } from 'src/app/dialogs/community/delete-community/delete-community.component';
import { EditCommunityComponent } from 'src/app/dialogs/community/edit-community/edit-community.component';
import { Router } from '@angular/router';
import { EditAccessCodeDialogComponent } from 'src/app/dialogs/community/edit-access-code-dialog/edit-access-code-dialog.component';
import { DocumentService } from 'src/app/_services/document.service';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {

  // data: DataTableCommunities[] = [];
  selection = new SelectionModel<DataTableCommunities>(true, []);
  filterValue: string;
  filterActivated: boolean;

  displayedColumns: string[] = ['select', 'nombre', 'municipio', 'codigoAcceso', 'actions'];
  resultsLength = 0;
  pageSizeOptions = 10;
  isLoadingResults = true;
  isRateLimitReached = false;

  communityEmmiter: EventEmitter<any> = new EventEmitter();
  newCommunityDialog: MatDialogRef<NewCommunityComponent>;
  editCommunityDialog: MatDialogRef<EditCommunityComponent>;
  deleteCommunityDialog: MatDialogRef<DeleteCommunityComponent>;
  editAccessCodeDialog: MatDialogRef<EditAccessCodeDialogComponent>;

  selectedRootFolders: any[] = [];

  data: DataTableCommunities[] = [];

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filterInput', { read: MatInput }) filterInput: MatInput;

  constructor(
    private communityService: CommunityService,
    private documentService: DocumentService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.sort.active = 'nombre';
    this.sort.direction = 'asc';
    this.filterValue = '';
    this.resultsLength = this.data.length;

    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
    });

    merge(this.sort.sortChange, this.communityEmmiter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.communityService.getCommunities(
            this.sort.active, this.sort.direction, this.paginator.pageIndex + 1, this.filterValue);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];

          return data['hydra:member'];
        }),
        catchError(error => {
          console.log('ERROR', error);
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data = data;
        this.selection.clear();
      });
  }

  newCommunity(): void {
    this.newCommunityDialog = this.dialog.open(NewCommunityComponent, {
      autoFocus: false
    });
    if (window.innerWidth < 600) {
      this.newCommunityDialog.updateSize('80vw', '87%');
    } else {
      this.newCommunityDialog.updateSize('869px', '');
    }

    this.newCommunityDialog.afterClosed().subscribe(result => {
      if (this.newCommunityDialog.componentInstance.created) {
        this.data = this.data.concat([this.newCommunityDialog.componentInstance.newCommunity]);
        this.reorderData();
      }
      this.newCommunityDialog = null;
    });
  }

  editCommunity(id: number): void {
    this.editCommunityDialog = this.dialog.open(EditCommunityComponent, {
      autoFocus: false
    });
    if (window.innerWidth < 600) {
      this.editCommunityDialog.updateSize('80vw', '87%');
    } else {
      this.editCommunityDialog.updateSize('869px', '');
    }

    this.editCommunityDialog.componentInstance.id = id;

    this.editCommunityDialog.afterClosed().subscribe(result => {
      if (this.editCommunityDialog.componentInstance.edited) {
        const community = this.data.find(x => x.id === id);
        const index = this.data.indexOf(community);
        const editedCommunity = this.editCommunityDialog.componentInstance.editedCommunity;
        this.data = this.data.slice(0, index).concat([editedCommunity]).concat(this.data.slice(index + 1));
        this.reorderData();
      }
      this.editCommunityDialog = null;
    });
  }

  deleteCommunity(id: number) {
    this.deleteCommunityDialog = this.dialog.open(DeleteCommunityComponent, {
      autoFocus: false,
    });
    this.deleteCommunityDialog.componentInstance.id = id;

    this.deleteCommunityDialog.afterClosed().subscribe(result => {
      if (this.deleteCommunityDialog.componentInstance.deleted) {
        const community = this.data.find(x => x.id === id);
        const index = this.data.indexOf(community);
        this.data = this.data.slice(0, index).concat(this.data.slice(index + 1));
      }
    });
  }

  editAccessCode(row: any) {
    this.editAccessCodeDialog = this.dialog.open(EditAccessCodeDialogComponent, {
      autoFocus: false,
    });
    if (window.innerWidth < 600) {
      this.editAccessCodeDialog.updateSize('80vw', '87%');
    } else {
      this.editAccessCodeDialog.updateSize('500px', '');
    }

    const accessCode = row.tieneCodigoAcceso ? row.codigoAcceso : '';
    this.editAccessCodeDialog.componentInstance.id = row.id;
    this.editAccessCodeDialog.componentInstance.accessCode.setValue(accessCode);

    this.editAccessCodeDialog.afterClosed().subscribe(result => {
      if (this.editAccessCodeDialog.componentInstance.edited) {
        const community = this.data.find(x => x.id === row.id);
        const index = this.data.indexOf(community);
        this.data[index].codigoAcceso = this.editAccessCodeDialog.componentInstance.accessCode.value;
        this.data[index].tieneCodigoAcceso = true;
      }
    });
  }

  downloadSelected() {
    this.communityService.getRootFolders(this.selection.selected.map(x => x.id)).subscribe(
      data => {
        // tslint:disable-next-line:no-string-literal
        this.selectedRootFolders = data['data'];
        this.downloadSelectedFiles();
      }
    );
  }

  downloadSelectedFiles() {
    this.documentService.downloadSelectedFiles(this.selectedRootFolders).subscribe(
      data => {
        if (data.byteLength > 0) {
          const blob = new Blob([data], {
            type: 'application/zip'
          });
          const url = URL.createObjectURL(blob);
          const downloadLink    = document.createElement('a');
          downloadLink.target   = '_blank';
          downloadLink.download = 'Documentos.zip';
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
        }
      }
    );
  }

  openCommunity(id: number) {
    this.router.navigate(['/communities/' + id + '/documents']);
  }

  paginatorChange() {
    this.communityEmmiter.emit();
  }

  reorderData() {
    const communities = this.data;

    const order1 = this.sort.direction === 'asc' ? -1 : 1;
    const order2 = this.sort.direction === 'asc' ? 1 : -1;
    const sort = this.sort.active;

    // tslint:disable-next-line:only-arrow-functions
    communities.sort(function(a, b) {
      if (a[sort].toLocaleLowerCase() < b[sort].toLocaleLowerCase()) { return order1; }
      if (a[sort].toLocaleLowerCase() > b[sort].toLocaleLowerCase()) { return order2; }
      return 0;
    });

    this.data = communities;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.data.forEach(row => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    this.filterValue = filterValue;
    this.communityEmmiter.emit();
  }

  clearFilter() {
    this.filterValue = '';
    this.communityEmmiter.emit();
  }

}

export interface DataTableCommunities {
  id: number;
  nombre: string;
  municipio: string;
  codigoAcceso: string;
  tieneCodigoAcceso: boolean;
}
