import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class UserService {

  private url: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getUser(id: number) {

    return this.http.get<any>(this.url + '/api/users/' + id);

  }

  getUsers(sort: string, order: string, page: number, filter: string, communityId: number): Observable<any> {

    sort = sort === 'comunidad' ? sort + '.nombre' : sort;

    return this.http.get<any>(this.url + '/api/users?page=' + page + '&order[' + sort + ']=' + order +
                              (filter === '' ? '' : '&username=' + filter) +
                              (filter === '' ? '' : '&comunidad.nombre=' + filter) +
                              (communityId ? '&comunidad.id=' + communityId : '') +
                              '&usuarioDeComunidad=false');
  }

  newUser(username: string, email: string, password: string, community: number) {

    const body = JSON.stringify({
      username,
      email,
      password,
      comunidad: '/api/comunidads/' + community,
      usuarioDeComunidad: false
    });

    return this.http.post<any>(this.url + '/api/users', body);

  }

  editUser(id: number, username: string, email: string, community: number) {

    let body;

    if (email !== '' && community !== -1) {
      body = JSON.stringify({
        username,
        email,
        comunidad: '/api/comunidads/' + community
      });
    } else if (email === '' && community !== -1) {
      body = JSON.stringify({
        username,
        comunidad: '/api/comunidads/' + community
      });
    } else if (email !== '' && community === -1) {
      body = JSON.stringify({
        username,
        email
      });
    } else {
      body = JSON.stringify({
        username
      });
    }

    return this.http.put<any>(this.url + '/api/users/' + id, body);

  }

  editPassword(id: number, currentPassword: string, newPassword: string) {

    let body;

    if (currentPassword !== '') {
      body = {
        id,
        currentPassword,
        newPassword
      };
    } else {
      body = {
        id,
        newPassword
      };
    }

    return this.http.post<any>(this.url + '/api/users/change_password', body);
  }

  deleteUser(id: number) {

    return this.http.delete<any>(this.url + '/api/users/' + id);

  }

  validateEmail(email: string) {

    const body = JSON.stringify({
      email
    });

    return this.http.post<any>(this.url + '/api/users/validate-email', body);

  }

}
