import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  accessCode: string;
  loading = false;
  hide = true;
  loginFailed = false;

  codeOrUsernamePass: number; // 0 - code / 1 - user pass

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.codeOrUsernamePass = parseInt(localStorage.getItem('codeOrUsernamePass'), 10);
    if (!this.codeOrUsernamePass) {
      this.codeOrUsernamePass = 0;
    }
    this.authenticationService.logout();
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.username, this.password)
        .subscribe(
            data => {
                localStorage.setItem('username', this.username);
                localStorage.setItem('token', data.token);
                localStorage.setItem('refresh_token', data.refresh_token);
                localStorage.setItem('role', data.roles[0]);
                this.authenticationService.setLoggedIn();
                this.router.navigate(['']);
            },
            error => {
                console.log('error', error);
                this.snackBar.open('Error al iniciar sesión', '', {
                  duration: 3000,
                });
                if (error.status === 402) {
                } else {
                    this.loading = false;
                    this.loginFailed = true;
                }
            });
  }

  loginWithCode() {
    this.loading = true;
    this.authenticationService.loginWithCode(this.accessCode)
        .subscribe(
            data => {
              console.log('hemen nago');
                localStorage.setItem('token', data.token);
                localStorage.setItem('refresh_token', data.refresh_token);
                localStorage.setItem('role', data.roles[0]);
                localStorage.setItem('username', data.username);
                this.authenticationService.setLoggedIn();
                this.router.navigate(['/documents']);
            },
            error => {
                console.log('error', error);
                this.snackBar.open('Error al iniciar sesión', '', {
                  duration: 3000,
                });
                if (error.status === 402) {
                } else {
                    this.loading = false;
                    this.loginFailed = true;
                }
            });
  }

  changeView(view: number) {
    this.codeOrUsernamePass = view;
    localStorage.setItem('codeOrUsernamePass', view.toString());
    this.loginFailed = false;
  }

}
