import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CommunityService } from 'src/app/_services/community.service';
import { MatButton, MatDialogRef } from '@angular/material';
import { ManagerService } from 'src/app/_services/manager.service';

@Component({
  selector: 'app-new-community',
  templateUrl: './new-community.component.html',
  styleUrls: ['./new-community.component.scss']
})
export class NewCommunityComponent implements OnInit {

  options: FormGroup;

  created: boolean;

  name = new FormControl('', [Validators.required]);
  cif = new FormControl('', [Validators.required]);
  city = new FormControl('', [Validators.required]);
  postalCode = new FormControl('', [Validators.required]);
  accountNumber = new FormControl('', [Validators.required]);
  communityInsurance = new FormControl('', [Validators.required]);
  elevatorOperator = new FormControl();
  elevatorOperatorPhone = new FormControl();
  plumber = new FormControl();
  plumberPhone = new FormControl();
  electricalTechnician = new FormControl();
  electricalTechnicianPhone = new FormControl();
  insuranceContact = new FormControl();
  insuranceContactPhone = new FormControl();
  accessCodeBoolean = new FormControl();
  accessCode = new FormControl('');

  managersIds: string[] = [];
  managers: {
    nombre: string,
    telefono: string,
    email: string
  }[] = [];

  newCommunity: {
    id: number,
    nombre: string,
    municipio: string,
    codigoAcceso: string,
    tieneCodigoAcceso: boolean
  };

  constructor(
    private communityService: CommunityService,
    private managerService: ManagerService,
    private matDialogRef: MatDialogRef<NewCommunityComponent>,
    fb: FormBuilder,
  ) {
    this.options = fb.group({
      color: 'primary'
    });
  }

  ngOnInit() {
    this.accessCodeBoolean.setValue(false);
    this.addManager();
  }

  submit(valid: boolean) {
    const self = this;

    if (!valid) {
      return;
    }

    if (this.managers.filter(x => x.nombre === '' || x.email === '' || x.telefono === '').length > 0) {
      return;
    }

    // tslint:disable-next-line: only-arrow-functions
    this.managers.forEach(function(manager, i) {
      self.managerService.newManager(manager.nombre, manager.telefono, manager.email).subscribe(
        data => {
          self.managersIds.push('/api/gestors/' + data.id);
          if (i === self.managers.length - 1) {
            self.submitCommunity();
          }
        }
      );
    });

    if (this.managers.length === 0) {
      this.submitCommunity();
    }
  }

  submitCommunity() {
    this.communityService.newCommunity(
      this.name.value, this.cif.value, this.city.value,
      this.postalCode.value, this.accountNumber.value,
      this.communityInsurance.value, this.managersIds,
      this.elevatorOperator.value, this.elevatorOperatorPhone.value,
      this.electricalTechnician.value, this.electricalTechnicianPhone.value,
      this.plumber.value, this.plumberPhone.value, this.insuranceContact.value,
      this.insuranceContactPhone.value, this.accessCodeBoolean.value, this.accessCode.value)
    .subscribe(
      data => {
        this.created = true;
        this.newCommunity = {
          id: data.id,
          nombre: this.name.value,
          municipio: this.city.value,
          codigoAcceso: this.accessCode.value,
          tieneCodigoAcceso: this.accessCodeBoolean.value
        };
        this.closeDialog();
      }
    );
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  createNewAccessCode() {
    if (this.accessCodeBoolean.value) {
      this.accessCode.setValue(Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(7, 9));
    } else {
      this.accessCode.setValue('');
    }
  }

  editAccessCode() {
    if (this.accessCodeBoolean.value) {
      if (this.accessCode.value.length < 5) {
        this.accessCode.setErrors({minlength: true});
      } else {
        this.communityService.isRepeatedCode(this.accessCode.value).subscribe(
          data => {
            if (data['data']) {
              this.accessCode.setErrors({ repeatedCode: true });
            }
          }
        );
      }
    } else {
      this.accessCode.setValue('');
    }
  }

  getClientNameErrorMessage() {
    return 'Campo obligatorio';
  }

  getAccessCodeErrorMessage() {
    let error = '';
    if (this.accessCode.hasError('minlength')) {
      error = 'Código de 5 caracteres';
    } else if (this.accessCode.hasError('repeatedCode')) {
      error = 'Código repetido';
    } else {
      error = 'Código de acceso obligatorio';
    }
    return error;
  }

  addManager() {
    this.managers.push({
      nombre: '',
      telefono: '',
      email: ''
    });
  }

  removeManager(index: number) {
    this.managers.splice(index, 1);
  }

}
