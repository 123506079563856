import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DocumentService } from 'src/app/_services/document.service';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-new-document',
  templateUrl: './new-document.component.html',
  styleUrls: ['./new-document.component.scss']
})
export class NewDocumentComponent implements OnInit {

  folderId: number;
  communityId: number;
  communityName: string;
  path: string[];
  fileList: File[] = [];
  uploadingFiles: boolean[] = [];
  filesToUpload: boolean[] = [];
  newFiles: {id: number, nombre: string, esCarpeta: boolean, esVisible: boolean}[] = [];
  sendNotification = true;

  constructor(
    private matDialogRef: MatDialogRef<NewDocumentComponent>,
    private documentService: DocumentService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    console.log(this.communityName, this.path);
  }

  uploadFiles() {
    let i = 0;
    this.fileList.forEach(file => {
      this.uploadingFiles[i] = true;
      this.uploadFile(file, i);
      i++;
    });
  }

  uploadFile(file: File, index: number) {
    this.documentService.uploadFile(file, this.folderId, this.communityId).subscribe(
      data => {
        this.filesToUpload[index] = false;
        this.uploadingFiles[index] = false;
        this.newFiles.push({
          id: data.id,
          nombre: data.nombre,
          esCarpeta: false,
          esVisible: true
        });
        if (this.sendNotification) {
          this.notify(data.nombre);
        }
      }
    );
  }

  notify(name: string) {
    let path = this.communityName;
    this.path.forEach(p => {
      path += '/' + p[1];
    });
    path += '/' + name;
    this.notificationService.newNotification('Nuevo documento añadido', path, [this.communityId]).subscribe();
  }

  handleFileInput(files: any) {
    console.log(files);
    if (files.length > 0) {
      for (const file of files) {
        this.fileList.push(file);
        this.filesToUpload.push(true);
        this.uploadingFiles.push(false);
      }
    }
  }

  removeFromList(index: number) {
    this.fileList.splice(index, 1);
    this.uploadingFiles.splice(index, 1);
    this.filesToUpload.splice(index, 1);
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  parseSize(bytes: number): string {
    let size: string;
    if      (bytes >= 1000000000) { size = (bytes / 1000000000).toFixed(2) + ' GB'; }
    else if (bytes >= 1000000)    { size = (bytes / 1000000).toFixed(2) + ' MB'; }
    else if (bytes >= 1000)       { size = (bytes / 1000).toFixed(2) + ' KB'; }
    else if (bytes > 1)           { size = bytes + ' bytes'; }
    else if (bytes === 1)         { size = bytes + ' byte'; }
    else                          { size = '0 bytes'; }
    return size;
  }

}
