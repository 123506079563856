import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { LayoutModule } from '@angular/cdk/layout';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { MatButtonModule, MatPaginatorIntl, MatSelectModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule, MatSidenavModule, MatListModule,
  MatCardModule, MatTableModule, MatCheckboxModule, MatSortModule,
  MatPaginatorModule, MatDividerModule, MatSlideToggleModule,
  MatTreeModule, MatAutocompleteModule, MatProgressBarModule,
  MatProgressSpinnerModule, MatTooltipModule, MatSnackBarModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './components/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CommunityComponent } from './components/community/community.component';
import { AuthenticationService } from './_services/authentication.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { CommunityService } from './_services/community.service';
import { getSpanishPaginatorIntl } from './_helpers/paginator/spanish';
import { AuthGuard } from './_guards/auth.guard';
import { RoleGuardService } from './_guards/role-guard.service';
import { NewCommunityComponent } from './dialogs/community/new-community/new-community.component';
import { DeleteCommunityComponent } from './dialogs/community/delete-community/delete-community.component';
import { EditCommunityComponent } from './dialogs/community/edit-community/edit-community.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { CreateFolderComponent } from './dialogs/documents/create-folder/create-folder.component';
import { DocumentService } from './_services/document.service';
import { NewDocumentComponent } from './dialogs/documents/new-document/new-document.component';
import { DragDropDirective } from './_helpers/dragDropDirective';
import { DeleteDocumentComponent } from './dialogs/documents/delete-document/delete-document.component';
import { DeleteFolderComponent } from './dialogs/documents/delete-folder/delete-folder.component';
import { EditFolderComponent } from './dialogs/documents/edit-folder/edit-folder.component';
import { EditDocumentComponent } from './dialogs/documents/edit-document/edit-document.component';
import { PreviewDocumentComponent } from './components/preview-document/preview-document.component';
import { UserComponent } from './components/user/user.component';
import { NewUserComponent } from './dialogs/user/new-user/new-user.component';
import { EditUserComponent } from './dialogs/user/edit-user/edit-user.component';
import { RemoveUserComponent } from './dialogs/user/remove-user/remove-user.component';
import { UserService } from './_services/user.service';
import { EditAccessCodeDialogComponent } from './dialogs/community/edit-access-code-dialog/edit-access-code-dialog.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationService } from './_services/notification.service';
import { NewNotificationComponent } from './dialogs/notification/new-notification/new-notification.component';
import { DeleteNotificationComponent } from './dialogs/notification/delete-notification/delete-notification.component';
import { EditNotificationComponent } from './dialogs/notification/edit-notification/edit-notification.component';
import { JwtModule } from '@auth0/angular-jwt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    ToolbarComponent,
    DashboardComponent,
    CommunityComponent,
    NewCommunityComponent,
    DeleteCommunityComponent,
    EditCommunityComponent,
    DocumentsComponent,
    CreateFolderComponent,
    NewDocumentComponent,
    DragDropDirective,
    DeleteDocumentComponent,
    DeleteFolderComponent,
    EditFolderComponent,
    EditDocumentComponent,
    PreviewDocumentComponent,
    UserComponent,
    NewUserComponent,
    EditUserComponent,
    RemoveUserComponent,
    EditAccessCodeDialogComponent,
    NotificationComponent,
    NewNotificationComponent,
    DeleteNotificationComponent,
    EditNotificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMatSelectSearchModule,
    LayoutModule,

    MatButtonModule,
    MatDatepickerModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatSliderModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatSnackBarModule,
    MatCardModule,
    MatTooltipModule,
    FormsModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    MatSelectModule,
    MatPaginatorModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,

    HttpClientModule,

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['example.com'],
        blacklistedRoutes: ['example.com/examplebadroute/']
      }
    }),

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],

  entryComponents: [
    NewCommunityComponent,
    DeleteCommunityComponent,
    EditCommunityComponent,
    CreateFolderComponent,
    NewDocumentComponent,
    DeleteDocumentComponent,
    DeleteFolderComponent,
    EditFolderComponent,
    EditDocumentComponent,
    PreviewDocumentComponent,
    NewUserComponent,
    EditUserComponent,
    RemoveUserComponent,
    EditAccessCodeDialogComponent,
    NewNotificationComponent,
    EditNotificationComponent,
    DeleteNotificationComponent
  ],

  providers: [
    CommunityService,
    DocumentService,
    UserService,
    NotificationService,
    AuthenticationService,
    AuthGuard,
    RoleGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

