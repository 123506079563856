import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { RoleGuardService as RoleGuard } from './_guards/role-guard.service';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CommunityComponent } from './components/community/community.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { PreviewDocumentComponent } from './components/preview-document/preview-document.component';
import { UserComponent } from './components/user/user.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { NotificationComponent } from './components/notification/notification.component';

const routes: Routes = [{
	path: '', component: ToolbarComponent, canActivate: [AuthGuard],
		children: [
			{ path: '', component: DashboardComponent },
			{ path: 'communities', component: CommunityComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_ADMIN' }},
			{ path: 'communities/:communityId/documents', component: DocumentsComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_ADMIN' }},
			{ path: 'communities/:communityId/documents/:folderId', component: DocumentsComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_ADMIN' }},
			{ path: 'communities/:communityId/documents/:folderId/:documentId', component: PreviewDocumentComponent },
			{ path: 'communities/:communityId/users', component: UserComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_ADMIN' }},
			// { path: 'users', component: UserComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_ADMIN' }},
			{ path: 'communities/:communityId/notifications', component: NotificationComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_ADMIN' }},
			{ path: 'notifications', component: NotificationComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_ADMIN' }},
			// tslint:disable-next-line:max-line-length
			{ path: 'documents', component: DocumentsComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_USER' }},
			{ path: 'documents/:folderId', component: DocumentsComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_USER' }},
			{ path: 'documents/:folderId/:documentId', component: PreviewDocumentComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_USER' }},
			{ path: 'notifications/:communityId', component: NotificationComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_USER'}}
		]
	},
	{ path: 'login', component: LoginComponent }
];


@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
