import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from 'src/app/_services/document.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss']
})
export class PreviewDocumentComponent implements OnInit {

  id: number;
  folder: number;
  communityId: number;
  pdfUrl: string;
  PDFSafe: any;
  pdf: any;

  path: string[] = [];
  pathRoot: number;

  loading: boolean;

  communityName: string;

  isAdmin: boolean;

  constructor(
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private documentService: DocumentService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('role') === 'ROLE_ADMIN';
    if (!this.id) {
      this.id = +this.route.snapshot.paramMap.get('documentId');
      this.folder = +this.route.snapshot.paramMap.get('folderId');
      this.communityId = +this.route.snapshot.paramMap.get('communityId');
    }
    this.loading = true;
    this.getPath();
    this.getDocument();
  }

  getDocument() {
    this.documentService.getDocumentPreview(this.id).subscribe(
      data => {
        // this.pdfViewer.openDocument(new Uint8Array(data));
        // this.pdfViewer.setZoomInPercent(150);
        this.pdf = new Blob([data], {type: 'application/pdf'});
        this.pdfUrl = URL.createObjectURL(this.pdf);
        this.PDFSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);
      },
      error => {
        if (error['status'] === 401) {
          this.location.back();
        }
      }
    );
  }

  getPath() {
    this.documentService.getPath(this.folder).subscribe(
      data => {
        // tslint:disable-next-line:no-string-literal
        this.path = data['data'];
        this.communityName = data['community'];
        this.pathRoot = parseInt(this.path[0][0], 10);
        this.path.splice(0, 1);
      }
    );
  }

  goToFolder(id: number) {
    console.log('go to folder', id);
    if (this.isAdmin) {
      if (id === null) {
        this.router.navigate(['communities/' + this.communityId + '/documents']);
      } else {
        this.router.navigate(['communities/' + this.communityId + '/documents/' + id]);
      }
    } else {
      if (id === null) {
        this.router.navigate(['/documents']);
      } else {
        this.router.navigate(['/documents/' + id]);
      }
    }
  }

  navigateToHome() {
    this.router.navigate(['communities']);
  }
}
