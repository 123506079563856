import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  username: string;
  token: string;
  refreshToken: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    if (this.router.url === '/') {
      if (localStorage.getItem('username')) {
        if (localStorage.getItem('role') === 'ROLE_ADMIN') {
          this.router.navigate(['communities']);
        } else {
          this.router.navigate(['documents']);
        }
      } else {
        this.router.navigate(['login']);
      }
    }
  }

}
