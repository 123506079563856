import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentService } from 'src/app/_services/document.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  isAdmin: boolean;
  communityId: number;

  constructor(
    private router: Router,
    private documentService: DocumentService
  ) { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('role') === 'ROLE_ADMIN';
    if (!this.isAdmin) {
      this.getCommunity();
    }
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

  getCommunity() {
    this.documentService.getCommunity().subscribe(
      data => {
        this.communityId = data['data'];
        console.log(this.communityId);
      }
    );
  }

}
