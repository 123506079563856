import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-remove-user',
  templateUrl: './remove-user.component.html',
  styleUrls: ['./remove-user.component.scss']
})
export class RemoveUserComponent implements OnInit {

  id: number;
  deleted: boolean;

  constructor(
    private userService: UserService,
    private matDialogRef: MatDialogRef<RemoveUserComponent>,
  ) { }

  ngOnInit() {
  }

  deleteUser() {
    this.userService.deleteUser(this.id)
      .subscribe(
        data => {
          this.deleted = true;
          this.matDialogRef.close();
        }
      );
  }

  closeDialog() {
    this.matDialogRef.close();
  }

}
