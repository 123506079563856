import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { catchError, map, switchMap, startWith } from 'rxjs/operators';
import { merge, of as observableOf } from 'rxjs';
import { MatDialogRef, MatSort, MatPaginator, MatDialog, MatInput } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { NewUserComponent } from 'src/app/dialogs/user/new-user/new-user.component';
import { EditUserComponent } from 'src/app/dialogs/user/edit-user/edit-user.component';
import { RemoveUserComponent } from 'src/app/dialogs/user/remove-user/remove-user.component';
import { CommunityService } from 'src/app/_services/community.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  selection = new SelectionModel<DataTableUsers>(true, []);
  filterValue: string;
  filterActivated: boolean;

  displayedColumns: string[] = ['select', 'username', 'comunidad', 'actions'];
  resultsLength = 0;
  pageSizeOptions = 10;
  isLoadingResults = true;
  isRateLimitReached = false;

  userEmmiter: EventEmitter<any> = new EventEmitter();
  newUserDialog: MatDialogRef<NewUserComponent>;
  editUserDialog: MatDialogRef<EditUserComponent>;
  deleteUserDialog: MatDialogRef<RemoveUserComponent>;

  defaultCommunityId: number;
  communityName: string;

  data: DataTableUsers[] = [];

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filterInput', { read: MatInput }) filterInput: MatInput;

  constructor(
    private userService: UserService,
    private communityService: CommunityService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.sort.active = 'username';
    this.sort.direction = 'asc';
    this.filterValue = '';
    this.resultsLength = this.data.length;

    this.route.params.subscribe(routeParams => {
      this.defaultCommunityId = routeParams.communityId;
      if (this.defaultCommunityId) {
        this.getCommunity();
      }
    });

    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
    });

    merge(this.sort.sortChange, this.userEmmiter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.userService.getUsers(
            this.sort.active, this.sort.direction, this.paginator.pageIndex + 1, this.filterValue, this.defaultCommunityId);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];

          return data['hydra:member'];
        }),
        catchError(error => {
          console.log('ERROR', error);
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data = data;
        this.selection.clear();
      });
  }

  newUser(): void {
    this.newUserDialog = this.dialog.open(NewUserComponent, {
      autoFocus: false
    });
    if (window.innerWidth < 600) {
      this.newUserDialog.updateSize('80vw', '87%');
    } else {
      this.newUserDialog.updateSize('869px', '');
    }

    this.newUserDialog.componentInstance.defaultCommunityId = this.defaultCommunityId;

    this.newUserDialog.afterClosed().subscribe(result => {
      if (this.newUserDialog.componentInstance.created) {
        this.userEmmiter.emit();
      }
      this.newUserDialog = null;
    });
  }

  editUser(id: number): void {
    this.editUserDialog = this.dialog.open(EditUserComponent, {
      autoFocus: false
    });
    if (window.innerWidth < 600) {
      this.editUserDialog.updateSize('80vw', '87%');
    } else {
      this.editUserDialog.updateSize('869px', '');
    }

    this.editUserDialog.componentInstance.id = id;
    this.editUserDialog.componentInstance.defaultCommunityId = this.defaultCommunityId;

    this.editUserDialog.afterClosed().subscribe(result => {
      if (this.editUserDialog.componentInstance.edited) {
        this.userEmmiter.emit();
      }
      this.editUserDialog = null;
    });
  }

  deleteUser(id: number) {
    this.deleteUserDialog = this.dialog.open(RemoveUserComponent, {
      autoFocus: false,
    });
    this.deleteUserDialog.componentInstance.id = id;

    this.deleteUserDialog.afterClosed().subscribe(result => {
      if (this.deleteUserDialog.componentInstance.deleted) {
        this.userEmmiter.emit();
      }
    });
  }

  paginatorChange() {
    this.userEmmiter.emit();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.data.forEach(row => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    this.filterValue = filterValue;
    this.userEmmiter.emit();
  }

  clearFilter() {
    this.filterValue = '';
    this.userEmmiter.emit();
  }

  getCommunity() {
    this.communityService.getCommunity(this.defaultCommunityId).subscribe(
      data => {
        this.communityName = data.nombre;
      }
    );
  }

  viewDocuments() {
    this.router.navigate(['communities/' + this.defaultCommunityId + '/documents']);
  }

  viewNotifications() {
    this.router.navigate(['communities/' + this.defaultCommunityId + '/notifications']);
  }

}

export interface DataTableUsers {
  id: number;
  username: string;
  comunidad: string;
}
