import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { MatCalendarBody } from '@angular/material';


@Injectable()
export class DocumentService {

  private url: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getDocument(id: number) {

    return this.http.get<any>(this.url + '/api/documentos/' + id);

  }

  getDocuments(folderId: number): Observable<any> {

    return this.http.get<any>(this.url + '/api/documentos');

  }

  getFolders(id: number, sort: string, order: string, filter: string) {

    return this.http.get(this.url + '/api/folders_table/' + id + '?order=' + order + '&sort=' + sort +
                        (filter === '' ? '' : '&filter=' + filter));
  }

  getRootFolder(id: number) {

    return this.http.get(this.url + '/api/comunidad/' + id + '/rootFolder');

  }

  newFolder(name: string, community: number, parent: number) {

    const body = JSON.stringify({
      nombre: name,
      comunidad: '/api/comunidads/' + community,
      parentCarpeta: '/api/carpetas/' + parent,
      esVisible: true
    });

    return this.http.post<any>(this.url + '/api/carpetas', body);

  }

  editCommunity(id: number, name: string, cif: string, city: string, pc: string,
                accountNumber: string, communityInsurance: string, manager: string, phone: string,
                email: string, operator: string, accessCode: string) {

    const body = JSON.stringify({
      nombre: name,
      cif,
      municipio: city,
      cp: pc,
      numeroCuenta: accountNumber,
      seguro: communityInsurance,
      gestor: manager,
      telefono: phone,
      email,
      ascensorista: operator,
      codigoAcceso: accessCode
    });

    return this.http.put<any>(this.url + '/api/comunidads/' + id, body);

  }

  deleteDocument(id: number) {

    return this.http.delete<any>(this.url + '/api/documentos/' + id);

  }

  deleteFolder(id: number) {

    return this.http.delete<any>(this.url + '/api/carpetas/' + id);

  }

  editFolderName(id: number, name: string) {

    const body = JSON.stringify({
      nombre: name
    });

    return this.http.put<any>(this.url + '/api/carpetas/' + id, body);

  }

  editDocumentName(id: number, name: string) {

    const body = JSON.stringify({
      nombre: name
    });

    return this.http.put<any>(this.url + '/api/documentos/' + id, body);

  }

  changeFolderVisibility(id: number, visible: boolean) {

    const body = JSON.stringify({
      esVisible: visible
    });

    return this.http.put<any>(this.url + '/api/carpetas/' + id, body);

  }

  changeDocumentVisibility(id: number, visible: boolean) {

    const body = JSON.stringify({
      esVisible: visible
    });

    return this.http.put<any>(this.url + '/api/documentos/' + id, body);

  }

  moveFolder(id: number, parent: number) {

    const body = JSON.stringify({
      parentCarpeta: '/api/carpetas/' + parent
    });

    return this.http.put<any>(this.url + '/api/carpetas/' + id, body);

  }

  moveDocument(id: number, parent: number) {

    const body = JSON.stringify({
      carpeta: '/api/carpetas/' + parent
    });

    return this.http.put<any>(this.url + '/api/documentos/' + id, body);

  }

  uploadFile(file: File, folder: number, community: number) {

    const formData: FormData = new FormData();
    formData.append('folder', folder.toString());
    formData.append('community', community.toString());
    formData.append('file', file);

    return this.http.post<any>(this.url + '/api/documentos/upload_file?x=' + Math.random(), formData);

  }

  getDocumentPreview(id: number) {

    return this.http.get(this.url + '/api/documentos/' + id + '/pdf', { responseType: 'arraybuffer' });

  }

  getPath(id: number) {

    return this.http.get(this.url + '/api/folders/' + id + '/path');

  }

  downloadSelectedFiles(files: any[]) {

    const formData: FormData = new FormData();
    formData.append('files', JSON.stringify(files));

    return this.http.post(this.url + '/api/documentos/download', formData, { responseType: 'arraybuffer' });

  }

  getCommunity() {

    return this.http.get(this.url + '/api/community/id');

  }

}
