import { Component, OnInit, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { MatDialogRef, MatDialog, MatSort, MatInput } from '@angular/material';
import { CreateFolderComponent } from 'src/app/dialogs/documents/create-folder/create-folder.component';
import { DocumentService } from 'src/app/_services/document.service';
import { NewDocumentComponent } from 'src/app/dialogs/documents/new-document/new-document.component';
import { DeleteDocumentComponent } from 'src/app/dialogs/documents/delete-document/delete-document.component';
import { DeleteFolderComponent } from 'src/app/dialogs/documents/delete-folder/delete-folder.component';
import { EditFolderComponent } from 'src/app/dialogs/documents/edit-folder/edit-folder.component';
import { EditDocumentComponent } from 'src/app/dialogs/documents/edit-document/edit-document.component';

import { Router, ActivatedRoute } from '@angular/router';
import { PreviewDocumentComponent } from '../preview-document/preview-document.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  data: DataTableDocuments[] = [];
  selection = new SelectionModel<DataTableDocuments>(true, []);
  filterValue: string;
  filterActivated: boolean;

  displayedColumns: string[] = ['select', 'nombre', 'actions'];
  resultsLength = 0;
  pageSizeOptions = 10;
  isLoadingResults = true;
  isRateLimitReached = false;

  documentEmmiter: EventEmitter<any> = new EventEmitter();
  newFolderDialog: MatDialogRef<CreateFolderComponent>;
  editFolderDialog: MatDialogRef<EditFolderComponent>;
  newDocumentDialog: MatDialogRef<NewDocumentComponent>;
  deleteDocumentDialog: MatDialogRef<DeleteDocumentComponent>;
  deleteFolderDialog: MatDialogRef<DeleteFolderComponent>;
  editDocumentDialog: MatDialogRef<EditDocumentComponent>;
  previewDocumentDialog: MatDialogRef<PreviewDocumentComponent>;

  communityId: number;
  parent: number;
  path: string[] = [];
  pathRoot = 0;

  currentUrl: string;

  communityName: string;

  dragOverPath: any;
  dragOverRow: any;
  dragRow: any;
  @ViewChild('emptySet') dragItem: ElementRef;

  dragWaitTimeMs = 1000;
  dragInsertOverTime: number;
  dragTimeRow: any;

  dragging = false;

  isAdmin: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filterInput', { read: MatInput }) filterInput: MatInput;

  constructor(
    private documentService: DocumentService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.isAdmin = localStorage.getItem('role') === 'ROLE_ADMIN';

    this.route.params.subscribe(routeParams => {
      this.communityId = routeParams.communityId;
      this.parent = routeParams.folderId;
      this.sort.active = 'nombre';
      this.sort.direction = 'asc';
      this.filterValue = '';
      this.getInfo();
    });

    this.sort.sortChange.subscribe(() => {
      this.getInfo();
    });
  }

  getInfo() {
    if (!this.communityId && !this.parent) {
      this.currentUrl = this.router.url + '/';
      this.getCommunity();
    } else if (this.communityId && !this.parent) {
      this.currentUrl = this.router.url + '/';
      this.getRootFolder();
    } else {
      this.currentUrl = this.router.url.substr(0, this.router.url.length - this.parent.toString().length);
      this.getPath();
      this.getDocuments();
    }
  }

  getPath() {
    this.documentService.getPath(this.parent).subscribe(
      data => {
        // tslint:disable-next-line:no-string-literal
        this.path = data['data'];
        this.communityName = data['community'];
        this.pathRoot = parseInt(this.path[0][0], 10);
        this.path.splice(0, 1);
      }
    );
  }

  getDocuments() {
    this.documentService.getFolders(
      this.parent, this.sort.active, this.sort.direction, this.filterValue)
    .subscribe(
      data => {
        // tslint:disable-next-line:no-string-literal
        this.data = data['data'];
      },
      error => {
        if (error['status'] === 401) {
          this.location.back();
        }
      }
    );
  }

  getCommunity() {
    this.documentService.getCommunity().subscribe(
      data => {
        this.communityId = data['data'];
        this.getRootFolder();
      }
    );
  }

  getRootFolder() {
    this.documentService.getRootFolder(this.communityId).subscribe(
      data => {
        // tslint:disable-next-line:no-string-literal
        this.pathRoot = data['id'];
        this.parent = data['id'];
        this.getPath();
        this.getDocuments();
      }
    );
  }

  newFolder(): void {
    this.newFolderDialog = this.dialog.open(CreateFolderComponent, {
      autoFocus: false
    });
    if (window.innerWidth < 600) {
      this.newFolderDialog.updateSize('80vw', '87%');
    } else {
      this.newFolderDialog.updateSize('450px', '');
    }
    this.newFolderDialog.componentInstance.parentFolder = this.parent;
    this.newFolderDialog.componentInstance.community = this.communityId;

    this.newFolderDialog.afterClosed().subscribe(result => {
      if (this.newFolderDialog.componentInstance.created) {
        this.data = this.data.concat([this.newFolderDialog.componentInstance.newFolder]);
        this.reorderData();
      }
      this.newFolderDialog = null;
    });
  }

  editRow(id: number, esCarpeta: boolean) {
    esCarpeta ? this.editFolder(id) : this.editDocument(id);
  }

  editFolder(id: number): void {
    this.editFolderDialog = this.dialog.open(EditFolderComponent, {
      autoFocus: false
    });
    if (window.innerWidth < 600) {
      this.editFolderDialog.updateSize('80vw', '87%');
    } else {
      this.editFolderDialog.updateSize('450px', '');
    }
    this.editFolderDialog.componentInstance.folderId = id;
    const name = this.data.find(x => x.id === id).nombre;
    this.editFolderDialog.componentInstance.name.setValue(name);

    this.editFolderDialog.afterClosed().subscribe(result => {
      if (this.editFolderDialog.componentInstance.edited) {
        const folder = this.data.find(x => x.id === id && x.esCarpeta);
        const index = this.data.indexOf(folder);
        this.data[index].nombre = this.editFolderDialog.componentInstance.name.value;
        this.reorderData();
      }
      this.editFolderDialog = null;
    });
  }

  editDocument(id: number): void {
    this.editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      autoFocus: false
    });
    if (window.innerWidth < 600) {
      this.editDocumentDialog.updateSize('80vw', '87%');
    } else {
      this.editDocumentDialog.updateSize('450px', '');
    }
    this.editDocumentDialog.componentInstance.documentId = id;
    const name = this.data.find(x => x.id === id).nombre;
    this.editDocumentDialog.componentInstance.name.setValue(name);

    this.editDocumentDialog.afterClosed().subscribe(result => {
      if (this.editDocumentDialog.componentInstance.edited) {
        const document = this.data.find(x => x.id === id && !x.esCarpeta);
        const index = this.data.indexOf(document);
        this.data[index].nombre = this.editDocumentDialog.componentInstance.name.value;
        this.reorderData();
      }
      this.editDocumentDialog = null;
    });
  }

  deleteRow(id: number, esCarpeta: boolean) {
    esCarpeta ? this.deleteFolder(id) : this.deleteDocument(id);
  }

  deleteDocument(id: number) {
    this.deleteDocumentDialog = this.dialog.open(DeleteDocumentComponent, {
      autoFocus: false,
    });
    this.deleteDocumentDialog.componentInstance.id = id;

    this.deleteDocumentDialog.afterClosed().subscribe(result => {
      if (this.deleteDocumentDialog.componentInstance.deleted) {
        const document = this.data.find(x => x.id === id && !x.esCarpeta);
        const index = this.data.indexOf(document);
        this.data = this.data.slice(0, index).concat(this.data.slice(index + 1));
      }
    });
  }

  deleteFolder(id: number) {
    this.deleteFolderDialog = this.dialog.open(DeleteFolderComponent, {
      autoFocus: false,
    });
    this.deleteFolderDialog.componentInstance.id = id;

    this.deleteFolderDialog.afterClosed().subscribe(result => {
      if (this.deleteFolderDialog.componentInstance.deleted) {
        const folder = this.data.find(x => x.id === id && x.esCarpeta);
        const index = this.data.indexOf(folder);
        this.data = this.data.slice(0, index).concat(this.data.slice(index + 1));
      }
    });
  }

  visible(id: number, esCarpeta: boolean, visible: boolean) {
    esCarpeta ? this.visibleFolder(id, visible) : this.visibleDocument(id, visible);
  }

  visibleFolder(id: number, visible: boolean) {
    this.documentService.changeFolderVisibility(id, visible).subscribe();
  }

  visibleDocument(id: number, visible: boolean) {
    this.documentService.changeDocumentVisibility(id, visible).subscribe();
  }

  uploadFile(): void {
    this.newDocumentDialog = this.dialog.open(NewDocumentComponent, {
      autoFocus: false
    });
    if (window.innerWidth < 600) {
      this.newDocumentDialog.updateSize('80vw', '87%');
    } else {
      this.newDocumentDialog.updateSize('869px', '');
    }
    this.newDocumentDialog.componentInstance.folderId = this.parent;
    this.newDocumentDialog.componentInstance.communityId = this.communityId;
    this.newDocumentDialog.componentInstance.communityName = this.communityName;
    this.newDocumentDialog.componentInstance.path = this.path;

    this.newDocumentDialog.afterClosed().subscribe(result => {
      if (this.newDocumentDialog.componentInstance.newFiles.length > 0) {
        this.data = this.data.concat(this.newDocumentDialog.componentInstance.newFiles);
        this.reorderData();
      }
      this.newDocumentDialog = null;
    });
  }

  clickRow(id: number, isCarpeta: boolean) {
    console.log('open', id, isCarpeta);
    isCarpeta ? this.openCarpeta(id) : this.previewDocument(id);
  }

  openCarpeta(id: number) {
    this.router.navigate([this.currentUrl + id]);
  }

  previewDocument(id: number) {
    this.router.navigate([this.currentUrl + this.parent + '/' + id]);
  }

  viewUsers() {
    this.router.navigate(['communities/' + this.communityId + '/users']);
  }

  viewNotifications() {
    this.router.navigate(['communities/' + this.communityId + '/notifications']);
  }

  handleDragStart(e, row: any) {
    this.dragging = true;
    this.dragRow = row;
    const id = row.id;
    const nombre = row.nombre;
    const esCarpeta = row.esCarpeta;
    const parent = this.parent;
    localStorage.setItem('dragRow', JSON.stringify({ id, nombre, esCarpeta, parent }));
    e.dataTransfer.setData('foo', 'bar');
    e.dataTransfer.setDragImage(this.dragItem.nativeElement, 0, 0);
  }

  handleDragEnd(e) {
    this.dragging = false;
    this.dragRow = null;
    this.dragOverRow = null;
    this.dragOverPath = null;
    this.dragInsertOverTime = 0;
    localStorage.removeItem('dragRow');
  }

  handleDragOver(e: Event, row: any) {
    e.preventDefault();
    this.dragOverPath = null;

    // if (row === this.dragOverRow) {
    //   if (this.dragRow !== row && row.esCarpeta) {
    //     if ((new Date().getTime() - this.dragInsertOverTime) > this.dragWaitTimeMs) {
    //       this.openCarpeta(row.id);
    //     }
    //   }
    // } else {
    this.dragOverRow = row;
    //   this.dragInsertOverTime = new Date().getTime();
    // }
  }

  handleDrop(e: Event, row: any) {
    const r = JSON.parse(localStorage.getItem('dragRow'));
    this.dragRow = {
      id: r['id'],
      nombre: r['nombre'],
      esCarpeta: r['esCarpeta']
    };
    if (!this.dragOverRow.esCarpeta) { this.dragOverRow.id = r['parent']; }
    e.preventDefault();
    if (this.dragOverRow) {
      if (this.dragRow.esCarpeta) {
        if (this.dragRow.id !== this.dragOverRow.id) {
          this.moveFolder(this.dragRow.id);
        }
      } else {
        if (this.dragOverRow.id !== this.parent) {
          this.moveDocument(this.dragRow.id);
        }
      }
    }
  }

  handleDropPath(e: Event, id: number) {
    if (this.dragRow.esCarpeta) {
      if (id !== this.dragRow.id) {
        this.moveFolder(this.dragRow.id);
      }
    } else {
      if (id !== this.parent) {
        this.moveDocument(this.dragRow.id);
      }
    }
  }

  handleDragOverPath(e: Event, id: number) {
    e.preventDefault();
    this.dragOverRow = null;

    // if (id === this.dragOverPath) {
    //   if ((new Date().getTime() - this.dragInsertOverTime) > this.dragWaitTimeMs) {
    //     this.openCarpeta(id);
    //   }
    // } else {
    this.dragOverPath = id;
    //   this.dragInsertOverTime = new Date().getTime();
    // }
  }

  moveFolder(id: number) {
    let overRowId: number;
    if (this.dragOverPath === null) {
      overRowId = this.dragOverRow.esCarpeta ? this.dragOverRow.id : this.parent;
    } else {
      overRowId = this.dragOverPath;
    }
    this.documentService.moveFolder(id, overRowId).subscribe(
      data => {
        this.getDocuments();
      }
    );
  }

  moveDocument(id: number) {
    let overRowId: number;
    if (this.dragOverPath === null) {
      overRowId = this.dragOverRow.esCarpeta ? this.dragOverRow.id : this.parent;
    } else {
      overRowId = this.dragOverPath;
    }
    this.documentService.moveDocument(id, overRowId).subscribe(
      data => {
        this.getDocuments();
      }
    );
  }

  downloadSelected() {
    this.documentService.downloadSelectedFiles(this.selection.selected).subscribe(
      data => {
        if (data.byteLength > 0) {
          const blob = new Blob([data], {
            type: 'application/zip'
          });
          const url = URL.createObjectURL(blob);
          const downloadLink    = document.createElement('a');
          downloadLink.target   = '_blank';
          downloadLink.download = 'Documentos.zip';
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
        }
      }
    );
  }

  paginatorChange() {
    this.documentEmmiter.emit();
  }

  reorderData() {
    const folders = this.data.filter(x => x.esCarpeta);
    const documents = this.data.filter(x => !x.esCarpeta);

    const order1 = this.sort.direction === 'asc' ? -1 : 1;
    const order2 = this.sort.direction === 'asc' ? 1 : -1;

    // tslint:disable-next-line:only-arrow-functions
    folders.sort(function(a, b) {
      if (a.nombre.toLocaleLowerCase() < b.nombre.toLocaleLowerCase()) { return order1; }
      if (a.nombre.toLocaleLowerCase() > b.nombre.toLocaleLowerCase()) { return order2; }
      return 0;
    });

    // tslint:disable-next-line:only-arrow-functions
    documents.sort(function(a, b) {
      if (a.nombre.toLocaleLowerCase() < b.nombre.toLocaleLowerCase()) { return order1; }
      if (a.nombre.toLocaleLowerCase() > b.nombre.toLocaleLowerCase()) { return order2; }
      return 0;
    });

    this.data = folders.concat(documents);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.data.forEach(row => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    this.filterValue = filterValue;
    this.getInfo();
  }

  clearFilter() {
    this.filterValue = '';
    this.getInfo();
  }

  navigateToHome() {
    this.router.navigate(['communities']);
  }

}

export interface DataTableDocuments {
  id: number;
  nombre: string;
  esCarpeta: boolean;
  esVisible: boolean;
}
