import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { stringify } from 'querystring';


@Injectable()
export class CommunityService {

  private url: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getCommunity(id: number) {

    return this.http.get<any>(this.url + '/api/comunidads/' + id);

  }

  getCommunities(sort: string, order: string, page: number, filter: string): Observable<any> {

    return this.http.get<any>(this.url + '/api/comunidads?page=' + page + '&order[' + sort + ']=' + order +
                              (filter === '' ? '' : '&nombre=' + filter) +
                              (filter === '' ? '' : '&municipio=' + filter));
  }

  getCommunitiesList(): Observable<any> {

    return this.http.get<any>(this.url + '/api/comunidads');

  }

  newCommunity(name: string, cif: string, city: string, pc: string,
               accountNumber: string, communityInsurance: string, managers: any[],
               elevatorOperator: string, elevatorOperatorPhone: string, electricalTechnician: string,
               electricalTechnicianPhone: string, plumber: string, plumberPhone: string, insuranceContact: string,
               insuranceContactPhone: string, accessCodeBoolean: boolean, accessCode: string) {

    const body = JSON.stringify({
      nombre: name,
      cif,
      municipio: city,
      cp: pc,
      numeroCuenta: accountNumber,
      seguro: communityInsurance,
      gestores: managers,
      ascensorista: elevatorOperator,
      telefonoAscensorista: elevatorOperatorPhone,
      electricista: electricalTechnician,
      telefonoElectricista: electricalTechnicianPhone,
      fontanero: plumber,
      telefonoFontanero: plumberPhone,
      contactoSeguro: insuranceContact,
      telefonoContactoSeguro: insuranceContactPhone,
      tieneCodigoAcceso: accessCodeBoolean,
      codigoAcceso: accessCode
    });

    return this.http.post<any>(this.url + '/api/comunidads', body);

  }

  editCommunity(id: number, name: string, cif: string, city: string, pc: string,
                accountNumber: string, communityInsurance: string, managers: any[],
                elevatorOperator: string, elevatorOperatorPhone: string, electricalTechnician: string,
                electricalTechnicianPhone: string, plumber: string, plumberPhone: string, insuranceContact: string,
                insuranceContactPhone: string, accessCodeBoolean: boolean, accessCode: string) {

    const body = JSON.stringify({
      nombre: name,
      cif,
      municipio: city,
      cp: pc,
      numeroCuenta: accountNumber,
      seguro: communityInsurance,
      gestores: managers,
      ascensorista: elevatorOperator,
      telefonoAscensorista: elevatorOperatorPhone,
      electricista: electricalTechnician,
      telefonoElectricista: electricalTechnicianPhone,
      fontanero: plumber,
      telefonoFontanero: plumberPhone,
      contactoSeguro: insuranceContact,
      telefonoContactoSeguro: insuranceContactPhone,
      tieneCodigoAcceso: accessCodeBoolean,
      codigoAcceso: accessCode
    });

    return this.http.put<any>(this.url + '/api/comunidads/' + id, body);

  }

  deleteCommunity(id: number) {

    return this.http.delete<any>(this.url + '/api/comunidads/' + id);

  }

  editAccessCode(id: number, accessCode: string) {

    const body = JSON.stringify({
      codigoAcceso: accessCode,
      tieneCodigoAcceso: true
    });

    return this.http.put<any>(this.url + '/api/comunidads/' + id, body);

  }

  isRepeatedCode(accessCode: string) {

    const body = JSON.stringify({
      accessCode
    });

    return this.http.post<any>(this.url + '/api/comunidads/accessCode', body);

  }

  getRootFolders(communities: number[]) {

    const formData: FormData = new FormData();
    formData.append('communities', JSON.stringify(communities));

    return this.http.post(this.url + '/api/comunidad/rootFolders', formData);

  }

}
